
const config = {
  "account": {
    "contact": "eschaefer@mindstreammediagroup.com",
    "id": "mindstream",
    "imageUrl": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/mindstream/assets/img/profile.png"
  },
  "assets": {
    "img": {
      "brand": {
        "backgrounds": {
          "profile": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/mindstream/assets/img/brand/backgrounds/profile.png"
        },
        "logo": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/mindstream/assets/img/brand/logo.png",
        "logo-white": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/mindstream/assets/img/brand/logo-white.png"
      }
    }
  },
  "footer": {
    "company": {
      "link": "https://mindstreammediagroup.com",
      "name": "Mindstream Media Group, Inc."
    }
  },
  "theme": {
    "palette": {
      "color-1": "#F4F6F5",
      "color-2": "#404040",
      "color-3": "#7CCBD7",
      "color-4": "#BBD773",
      "color-5": "#FCFCFC"
    }
  }
}

export default config;
